<template>

  <transition name="fade">

    <div :class="widgetClasses" class="card">
      
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">{{$t("Vos contrats à échéance")}}</span>

          <span class="text-muted mt-1 fw-bold fs-7" v-if="!state.loaderEnabled"
            >{{ state.myList.length }} {{$t("contrat(s) expirés ou bientôt expirés")}}</span
          >
        </h3>

        <div v-if="!state.loaderEnabled && state.myList.length == 0" class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10" style="width: 100%; margin-top: 20px;">
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucune échéance")}}</h4>
            <span>{{$t("Il n'existe pour le moment aucune échéance de contrat à suivre.")}}</span>
          </div>
        </div>


        <div style="width: 100%; height: 100%; text-align: center; padding-top: 20px; padding-bottom: 50px" v-if="state.loaderEnabled" >
          <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
        </div>

      </div>

      <div class="card-body py-3" v-if="!state.loaderEnabled">
        <div class="table-responsive">

                <table class="table align-middle gs-0 gy-3">

                  <thead>
                    <tr>
                      <th class="p-0 w-50px"></th>
                      <th class="p-0 min-w-150px"></th>
                      <th class="p-0 min-w-140px"></th>
                      <th class="p-0 min-w-120px"></th>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-for="(item1, index1) in state.myList" :key="index1">
                      <tr>
                        <td>
                          <div class="symbol symbol-50px">
                            <img :src="imgConst(item1.const_seq)"  v-if="imgConst(item1.const_seq) && item1.const_seq" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" />
                          </div>
                        </td>
                        <td>
                          <span class="text-dark fw-bolder mb-1  fs-6">{{ item1.code }}</span>
                          <span class="text-muted fw-bold d-block fs-7">
                            {{ item1.cont_libelle }}</span>
                        </td>
                        <td>
                          <div v-if="test_before_today(item1.fsh_date_fin)" class=" m-1 me-5 text-dark fs-7 bg-light-warning p-2" style="width: 280px">
                            <span class="svg-icon svg-icon-2 svg-icon-warning me-3"><inline-svg src="media/icons/duotune/general/gen043.svg" /></span>
                            <span>{{$t("Contrat actif jusqu'au")}} <b>{{ formatDate(item1.fsh_date_fin) }}</b></span>
                          </div>
                          <div v-else class=" m-1 me-5 text-dark fs-7 bg-light-danger p-2" style="width: 280px">
                            <span class="svg-icon svg-icon-2 svg-icon-danger me-3"><inline-svg src="media/icons/duotune/general/gen044.svg" /></span>
                            <span>{{$t("Contrat inactif depuis le")}} <b>{{ formatDate(item1.fsh_date_fin) }}</b></span>
                          </div>
                        </td>
                        <td class="text-end">
                          <!--
                          <button type="button" class="btn btn-sm btn-text-icon btn-active-primary mb-2 me-5 btn-bg-white btn-text-dark" @click="state.dialogFormVisible = true">
                              <span class="svg-icon svg-icon-1 svg-icon-primary">
                                  <inline-svg src="media/icons/duotune/general/gen029.svg" />
                              </span>
                              <span>
                                  Demande de renouvellement
                              </span>
                          </button>
                          -->
                          <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click='affDetail(item1)'>
                            <span class="svg-icon svg-icon-1 svg-icon-primary">
                              <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                              {{$t("Détail du contrat")}}
                          </a>
                        </td>
                      </tr>
                    </template>
                  </tbody>

                </table>
              </div>
      </div>

    </div>
  </transition>
  <el-drawer v-model="state.drawer" :with-header="false" size="100%" modal-class="modal-transp" v-show="state.contratSelect.fsh_contract_number">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Détail de votre contrat")}} {{ state.contratSelect.constructeur }} {{$t("N°")}} {{ state.contratSelect.fsh_contract_number }}
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
          <DetailContrat @changeContrat="changeContrat($event)" :zContrat="state.contratSelect" :key="state.contratSelect.fsh_contract_number" card-classes="mb-12"></DetailContrat>
      </div>
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent,onMounted, reactive } from "vue";
import * as moment from "moment";
import DetailContrat from "@/views/still/contrats/DetailContrat.vue";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-widget-4",
  components: {DetailContrat},
  props: {
    widgetClasses: String,
  },
  setup() {

    //moment.default.locale("fr");

    const state = reactive({
      drawer: false,
      loaderEnabled: true,
      initialMyList: [],
      myList: [],
      contratSelect: {},
    });


    onMounted(async () => {
      let myList = await mAxiosApi.prototype.getAxios("/getContratSupport/-1");
      state.myList = myList.results;      
      //console.log(state.myList);
      state.loaderEnabled = false;
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_before_today = (d) => {
      const currentDate = moment.default();
      return moment.default(d) > currentDate;
    }

    const affDetail = async (data) => {
      // console.log('Detail',data);
      state.contratSelect = data;
      state.drawer = true;
    };

    const changeContrat = async (ev) => {
      let myList = await mAxiosApi.prototype.getAxios("/getContratSupport/-1");
      state.myList = myList.results;   

    }

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    return {
      state,
      imgConst,
      formatDate,
      test_before_today,
      affDetail,
      changeContrat
    };
  },
});
</script>