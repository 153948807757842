<template>
  <transition name="fade">
    <!--begin::List Widget 5-->
    <div class="card" :class="widgetClasses" v-if="!state.loaderEnabled">
      <!--begin::Header-->
      <div class="card-header align-items-center border-0 mt-4">
        <div class="card-title">
          <h3 class="fw-bolder mb-0">{{$t("Equipements prochainement non supportés")}}</h3>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-5">
        <!--begin::Timeline-->
        <div class="timeline-label">
          <!--begin::Item-->
          <div
            class="timeline-item"
            v-for="(item, index) in state.list"
            :key="index"
          >
            <!--begin::Label-->
            <div class="timeline-label fw-bolder text-gray-600 fs-6">
              {{ item.date }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="fa fa-genderless fs-1" :class="item.class"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div class="fw-mormal timeline-content text-muted ps-3">
              <span class="text-gray-800">
                {{$t("Fin de support de")}}  
                <span class="text-primary text-hover-inverse-primary bg-hover-primary p-1"  @click="affDetail(item.fsh_product_number, item.parent_product_number, item.fsh_knum_constructeur)">
                  <b>{{ item.fsh_product_number }}</b>
                </span> - {{$t("Contrat N°")}}
                <span class="text-dark"><b>{{ item.fsh_contract_number }}</b></span>
              </span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </div>
        <!--end::Timeline-->
      </div>
      <!--end: Card Body-->
    </div>
    <!--end: List Widget 5-->
  </transition>
  <el-drawer v-model="state.drawerDetail" :with-header="false" size="100%" modal-class="modal-transp">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawerDetail = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Détail de l'équipement")}} {{ state.equipementSelect.constructeur }} <span class='text-gray-500'>&nbsp;&nbsp;{{ state.equipementSelect.fsh_product_number }}</span>&nbsp;
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
          <DetailEquipement :zEquipement="state.equipementSelect" :key="state.equipementSelect.fsh_product_number" :defaultSearch="state.defaultSearch" card-classes="mb-12"></DetailEquipement>
      </div>
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import DetailEquipement from "@/views/still/contrats/DetailEquipement.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  components: {
    DetailEquipement
  },
  setup() {
    const state = reactive({
      list: [],
      loaderEnabled: true,
      equipementSelect: {},
      drawerDetail: false,
      defaultSearch : "",
    });
    
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getEosWarrantyListEquipement");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    
    const affDetail = async (ref, ref_parent, constructeur) => {

      let myList = await mAxiosApi.prototype.getAxios("/getEquipementContratbyRef/" +  window.btoa(ref_parent) + "/" + constructeur);
      
      if (ref !== ref_parent) state.defaultSearch = ref;

      state.equipementSelect = myList.results[0];
      state.drawerDetail = true;
    };


    return {
      state,
      affDetail,
    };
  },
});
</script>
